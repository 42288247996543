import {ReactElement, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {Card} from "react-bootstrap";
import LoginToken from "./LoginToken";
import {Link} from "react-router-dom";
import {ButtonFancy} from "../basic/ButtonFancy";

export default function ProfileLoginToken(props: {}): ReactElement {
    // const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();

    return <div className="container d-flex align-items-center justify-content-center">
        <Card style={{maxWidth: "800px"}} className="w-100 bg-fancy text-white">
            <div className="bg-fancy-offset"/>
            <h2 className="text-center">Login token</h2>

            <MessageDisplay message={message}/>

            <LoginToken instant={true} messageSet={messageSet}></LoginToken>

            <Link to="/app"><ButtonFancy type="button" className="d-inline-block">Go to profile</ButtonFancy></Link>
        </Card>
    </div>;
}