import React from "react";
import {MessageDisplay} from "../components/Message";
import {Link} from "react-router-dom";

export default function NotFoundPage() {
    return (
        <div className="container p-4">
            <h1>Site not found</h1>
            <MessageDisplay message={{type: "warning", message: "Sorry, this site does not exist."}}/>
            <Link to="/" className="text-center d-block">Go back to Home.</Link>
        </div>);
};