import jwtDecode from "jwt-decode";
import useCookie from "./useCookie";

type AuthenticationObject<T = unknown> = {
    authenticated: boolean,
    payload?: T
}

function LOGGED_OUT(): AuthenticationObject<undefined> { return { authenticated: false, payload: undefined } }
function LOGGED_IN<T = unknown>(pl: T | undefined):AuthenticationObject<T> { return {authenticated: true, payload: pl} }

export default function useAuth<T = unknown>(): AuthenticationObject<T | undefined> {

    const token = useCookie("_token")

    if (typeof token !== "string")
        return LOGGED_OUT()
    
    const payload = jwtDecode<T>(token)

    return LOGGED_IN(payload)

}