import {Card, Form} from "react-bootstrap";
import React, {FormEvent, useCallback, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {EnvelopeAt} from "react-bootstrap-icons";
import InputControlled from "../components/InputControlled";
import {Link} from "react-router-dom";
import {authForgotRequest} from "./AuthenticationService";
import {AxiosError} from "axios";
import {errorMessageBase, ErrorObject, getError} from "../api/apiConfig";
import {ButtonFancy} from "../basic/ButtonFancy";

export const ForgotPage = (): JSX.Element => {
    const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [stage, stageSet] = useState(0);
    const [email, emailSet] = useState('');

    const handleEmail = useCallback((event: FormEvent) => {
        event.preventDefault();
        if(submitting) return;

        submittingSet(true);
        authForgotRequest(email).then(() => {
            stageSet(1);
            messageSet(undefined);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, errorMessageBase)});
        }).finally(() => submittingSet(false));
    }, [email, submitting]);

    return <div className="container d-flex align-items-center justify-content-center" style={{height: "100vh"}}>
        <Card style={{maxWidth: "600px"}} className="w-100 bg-fancy">
            {/*Input email*/ stage===0 && <>
                <Form onSubmit={handleEmail} className="bg-fancy-offset">
                    <Card.Body>
                        <Card.Title>Reset Password</Card.Title>
                        <MessageDisplay message={message}/>
                        <h6>Please input your e-mail. We will send you a link to reset your password.</h6>

                        <Form.Group>
                            <Form.Label><EnvelopeAt/> Account E-mail</Form.Label>
                            <InputControlled value={email} name="email" className="form-control" handleChange={(v: string) => emailSet(v)}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex py-2 flex-column align-items-center'>
                            <ButtonFancy type="submit" disabled={submitting || !email.includes('@')}>Request Security Code</ButtonFancy>
                        </div>
                        <div className="d-flex py-2 flex-column align-items-center small">
                            <p className='text-muted mb-0'>Remember your password?</p>
                            <Link to="/app/login">Back to Login</Link>
                        </div>
                    </Card.Footer>
                </Form>
            </>}
            {/*Open email message*/ stage===1 && <>
                <Card.Body className="bg-fancy-offset">
                    <Card.Title>Reset Password</Card.Title>

                    <h5><span className="text-success">We sent you an e-mail!</span></h5>
                    Please follow the instructions in the e-mail. Please also check your spam folder.
                </Card.Body>
            </>}
        </Card>
    </div>;
};