import React, {FormEvent, ReactElement, useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import InputControlled from "../components/InputControlled";
import {Button} from "react-bootstrap";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {TwitchEmbed} from "react-twitch-embed";

declare global {
    interface Window {
        Twitch: any
    }
}
export default function TwitchPage(): ReactElement {
    const [streamerInput, streamerInputSet] = useState("");
    const [streamer, streamerSet] = useState<string>();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryStreamer = queryParams.get('streamer');
        if (queryStreamer) streamerSet(queryStreamer);
    }, [location.search]);

    const streamerButton = useCallback((e: FormEvent) => {
        e.preventDefault();
        // const queryParams = new URLSearchParams(location.search);
        // queryParams.set("streamer", streamerInput);
        navigate("./?streamer=" + streamerInput);
        streamerSet(streamerInput);
    }, [streamerInput, navigate]);

    // <script src="https://embed.twitch.tv/embed/v1.js"></script>
    return <div className="w-100 overflow-hidden position-relative" style={{height: '100vh', backgroundColor: 'gray'}}>
        {streamer && <LoadedPage streamer={streamer}/>}
        {!streamer && <div className="container mt-4">
            <form onSubmit={streamerButton}>
                <InputControlled className="form-control" value={streamerInput}
                                 handleChange={v => streamerInputSet(v)}></InputControlled>
                <Button className="btn-success" type="submit">Watch this streamer</Button>
            </form>
        </div>}
    </div>;
}

const LoadedPage = (props: { streamer: string }): ReactElement => {
    const {viewportWidth, viewportHeight} = useWindowDimensions();

    const rightBand = 0;
    const twitchLeft = 0; //240 - 10;
    const twitchRight = 343; // - 10;

    const viewWidth = viewportWidth - (twitchLeft + twitchRight) - rightBand;

    const aspectRatioIdeal = 16 / 9.0;
    const aspectRatioReal = viewWidth / viewportHeight;

    // wider than ideal
    const scaleDebugFactor = 1;
    let width = viewWidth * scaleDebugFactor;
    let height = viewportHeight * scaleDebugFactor;
    // wider than ideal
    if (aspectRatioReal > aspectRatioIdeal) {
        width = height * aspectRatioIdeal;
    }
    // taller than ideal
    else {
        height = width / aspectRatioIdeal;
    }

    /*
    <div className="position-absolute w-100" style={{paddingLeft: twitchLeft, paddingRight: twitchRight, top: "50px"}}>
            <iframe className="w-100 top-0" style={{height: "100vh"}}
                    src={"https://twitch1.fairytalefables.com?streamer=" + props.streamer} width="100%"
                    height="100%"></iframe>
        </div>
     */

    // <iframe className="position-absolute top-0" src={"https://twitch.tv/" + props.streamer} width="100%" height="100%"></iframe>
    return <>
        <div className="position-absolute top-0 h-100" style={{width:rightBand+"px"}} />
        <TwitchEmbed className="position-absolute top-0" width="100%" height="100%" channel={props.streamer} autoplay withChat />

        <iframe title="Twitch embed" className="position-absolute" style={{
            width: width + "px",
            height: height + "px",
            top: ( (viewportHeight-height)/2 ) + "px",
            marginLeft: twitchLeft
        }}
                src={"https://twitch1.fairytalefables.com?streamer=" + props.streamer} width="100%"
                height="100%"></iframe>
    </>;
}
