import axios, {AxiosResponse} from "axios";
import {API_BASE, API_CONFIG} from "../api/apiConfig";

const API = API_BASE + "/game/payments.php"

export type PaymentBookTypes = "books-small" | "books-large" | "books-huge" | "books-endless";
export type PaymentTypes = "subscription" | PaymentBookTypes;


export interface BookInfoDto {
    amount: number;
    borders: string[];
}

export interface BookInfo {
    name: string;
    description: string;
    price: number;
}

export const BookDict: Record<PaymentBookTypes, BookInfo> = {
    "books-small": {
        name: "Small Stack of Fairytales",
        description: "Unlock Fairytales to progress faster in-game.",
        price: 3.99,
    },
    "books-large": {
        name: "Large Stack of Fairytales",
        description: "Unlock Fairytales to progress faster in-game.",
        price: 9.99,
    },
    "books-huge": {
        name: "Huge Stack of Fairytales",
        description: "Unlock Fairytales to progress faster in-game.",
        price: 24.99,
    },
    "books-endless": {
        name: "Immeasurable Fairytales",
        description: "Unlock Fairytales to progress faster in-game.",
        price: 99.99,
    }
}

export function payment(type: PaymentTypes): Promise<AxiosResponse<string>> {
    return axios.post(API+"?action=" + type, {}, API_CONFIG());
}

export function paymentBook(type: PaymentTypes, borderName: string): Promise<AxiosResponse<string>> {
    return axios.post(API+"?action=" + type + "&borderName=" + borderName, {}, API_CONFIG());
}
