import React, { useEffect, useState } from "react";
import { Link, useMatches } from "react-router-dom";

export type Crumb = { path: string, title: string }

export function useBreadcrumbs(): Crumb[] {
    const matches: any[] = useMatches();
    const [crumbs, setCrumbs] = useState<Crumb[]>([]);

    useEffect(() => {
        let c = matches
        .filter((match) => Boolean(match.handle) && Boolean(match.handle.crumb))
        .map(match => match.handle.crumb)
        setCrumbs(c)
    }, [matches]);

    return crumbs
}

export function bake(crumb: Crumb, isLast?: boolean): JSX.Element {
    return !!isLast ? <>{crumb.title}</> : <Link to={crumb.path}>{crumb.title}</Link>
}

export default function BreadcrumbNavigation() {

    const crumbs: Crumb[] = useBreadcrumbs();

    return  <ol>
                {crumbs.map((crumb, index) => (
                <li key={index}>{bake(crumb)}</li>
                ))}
            </ol>
}