import React, {ReactElement} from "react";
import {Card} from "react-bootstrap";

export const DeleteAccountPage = (): ReactElement => {
    return <div className="container d-flex align-items-center justify-content-center">
        <Card style={{maxWidth: "800px",marginTop:"100px",marginBottom:"50px"}} className="w-100 bg-fancy text-white">
            <div className="bg-fancy-offset"/>
            <h2 className="text-center">Delete your Account</h2>
            <h4>Steps to delete your Account</h4>
            <ol>
                <li>Write us an E-Mail, explaining the reason for your account deletion to <a className="text-warning" href="mailto:support@fairytalefables.com">support@fairytalefables.com</a>.</li>
                <li>We will delete your account info after 5 business days.</li>
            </ol>
        </Card>
    </div>
};