import {API_BASE, API_CONFIG, errorMessageBase} from "../api/apiConfig";
import axios, {AxiosResponse} from "axios";
import {BookInfoDto, PaymentBookTypes} from "../payments/PaymentService";

const API = API_BASE + "/game"

export interface UserInfoDto {
    id: number;
    name: string;

    mmr: number;
    mmrChaos: number;
    mmrAsync: number;
    mmrChaosAsync: number;

    mmrMax: number;
    mmrChaosMax: number;
    mmrAsyncMax: number;
    mmrChaosAsyncMax: number;

    gameCountLife: number;
    gameCountLifeChaos: number;
    gameCountLifeAsync: number;
    gameCountLifeChaosAsync: number;

    avgPlacementLife: number;
    avgPlacementLifeChaos: number;
    avgPlacementLifeAsync: number;
    avgPlacementLifeChaosAsync: number;

    crystals: number;
    books: number;
    crowns: number;

    subscriptionUntil: string;
    twitchUsername?: string;
}

export interface ProfileDto {
    userInfo: UserInfoDto;
    books: Record<PaymentBookTypes, BookInfoDto>;
    bundleBordersOwned: string[];
    bundleBordersNamesDict: Record<string, string>;
}

export const gameUserInfoErrors: string[] = errorMessageBase.concat([
    "Sorry, we could not find a user with this E-mail/password. Please check your input and try again."
]);

export function gameProfileDto(): Promise<AxiosResponse<ProfileDto>> {
    return axios.get(API+"/shop.php?action=website-profile", API_CONFIG());
}