import Cookies from "universal-cookie/es6";
import jwtDecode from "jwt-decode";

export const WEBSITE_BASE = process.env.REACT_APP_WEBSITE_URL;
export const API_BASE = process.env.REACT_APP_API_URL;

export const C = new Cookies();

export const API_CONFIG = () => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Authorization": getToken()
        }
    }
};

export function setToken(token: string) {
    C.set("_token", token, {path: '/'});
}

export function removeToken() {
    C.set("_token", "", {path: '/', expires: new Date()});
}

export function getToken(): string {
    return C.get("_token");
}

export interface Payload {
    id: number;
}

export function getPayload(): Payload {
    const jwt = C.get("_token");
    //if(!jwt) return undefined;
    return jwtDecode(jwt);
}

export function isLoggedIn(): boolean {
    return C.get("_token")?.length > 0;
}

export const errorMessageBase: string[] = [
    "Sorry, some error occured. Please try again or refresh the page.",
    "You are not logged in. Please login.",
    "There was a programming failure. Please write the Support: 'There are missing input parameters.'", //reserved for generic message
    "",
];

export interface ErrorObject {
    error?: string,
    errorCode?: number
}

export const getError = (errorObject: ErrorObject | undefined, errorMessages: string[]) => {
    if (errorObject) {
        if (errorObject.errorCode !== undefined && errorObject.errorCode < errorMessages.length) return errorMessages[errorObject.errorCode];
        if (errorObject.error) return errorObject.error;
    }
    return "Error";
}