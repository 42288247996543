import { AnimatePresence, motion } from "framer-motion";
import { bake, Crumb, useBreadcrumbs } from "../components/Breadcrumb";

export default function AppOutletBreadcrumb() {
    const crumbs: Crumb[] = useBreadcrumbs();

    return  <div style={{overflowY: "hidden", paddingTop: "82px"}}>
                <AnimatePresence>
                    {crumbs.length >1 && <motion.div
                        initial={{
                            height: 0,
                            opacity: 0
                        }} 
                        animate={{
                            height: "auto",
                            opacity: 1
                        }} 
                        exit={{
                            height: 0,
                            opacity: 0
                        }}
                        transition={{
                            delay: .5,
                            duration: 1
                        }}>
                        <section className="breadcrumbs" style={{marginTop: 0}}>
                            <div className="container">
                                <ol className='mb-0'>
                                    {crumbs.map((crumb, index) => (
                                        <li key={index}>{bake(crumb, index === crumbs.length - 1)}</li>
                                    ))}
                                </ol>
                                <h2 className='mb-0'>{crumbs[crumbs.length - 1].title}</h2>
                            </div>
                        </section>
                    </motion.div>}
                </AnimatePresence>
            </div>
    }