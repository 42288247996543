import React, {ReactElement} from "react";

export const ButtonFancy = (props: {
    bg?:"green"|"purple"|"blue"|"red",
    type?: "button" | "submit" | "reset" | undefined,
    disabled?: boolean,
    children?: React.ReactNode,
    onClick?: () => void,
    className?: string
}): ReactElement => {
    return <button onClick={props.onClick} className={'btn-fancy bg-shade bg-' + (props.bg ? props.bg : 'green') + " " + props.className}
                   type={props.type ? props.type : 'button'} disabled={props.disabled}>
        <div className='btn-fancy-border'/>
        {props.children}
    </button>;
};
