import {MessageState} from "../components/Message";
import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {authLoginTokenRequest} from "../authentication/AuthenticationService";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorObject, getError} from "../api/apiConfig";
import {gameUserInfoErrors} from "./GameService";
import {ButtonFancy} from "../basic/ButtonFancy";

const LoginToken = (props: {instant?: boolean, messageSet: (m:MessageState) => void }): ReactElement => {
    const [waiting, waitingSet] = useState(false);
    const [token, tokenSet] = useState("");

    const request = useCallback(() => {
        if(waiting) return;
        waitingSet(true);

        authLoginTokenRequest().then((response: AxiosResponse<string>) => {
            tokenSet(response.data);
            navigator.clipboard.writeText(response.data).then();
            waitingSet(true);
        }, (error: AxiosError<ErrorObject>) => {
            props.messageSet({type: "danger", message: getError(error.response?.data, gameUserInfoErrors)});
        })

    }, [token, waiting]);

    useEffect(() => {
        if(!props.instant) return;
        request();
    }, [request, props.instant]);

    return <div className="mb-2" id="logintoken">
        <div>Get a token to login. The token is valid for 3 minutes and can be used to login to your account
            in-game.
        </div>
        <div className="p-2">
            {token ? <div>
                    <div className="text-success">Token copied to clipboard! Please input the copied text in-game.</div>
                    <input type="text" value={token}></input>
                </div>
                : <ButtonFancy type="button" disabled={waiting} onClick={request}>Get Token</ButtonFancy>}
        </div>
    </div>;
}
export default LoginToken;