import {API_BASE, API_CONFIG} from "../api/apiConfig";
import axios from "axios";
import ReactGA from "react-ga4";

const API = API_BASE + "/website/website.php"
console.log("Analytics base " + API)

export function analyticsPageViewCurrent(): void {
    analyticsPageView(window.location.pathname + window.location.search);
}

export function analyticsPageView(link: string): void {
    if(ReactGA.isInitialized) ReactGA.send({ hitType: "pageview", page: link });
    axios.post(API + "?action=pageview", {link, userAgent: window.navigator.userAgent}, API_CONFIG()).then(r  => {}).catch(() => {});
}
