import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

interface ControlledProps<T> extends React.HTMLAttributes<HTMLInputElement> {
    value: T,
    handleChange?: (v: T) => void,
    name?: string,
    disabled?: boolean,
    required?: boolean,
    checkValid?: (value: T) => boolean,
    maxlength?: number,
    pattern?: string,
    autoComplete?: string,
}

interface InputControlledProps<T> extends ControlledProps<T> {
    type?: "text" | "number" | "date" | "password" | "email",
    placeholder?: string,
}

function onChange<T>(v: T, setValue: React.Dispatch<React.SetStateAction<T>>, changeFunction?: (v: T) => void,) {
    setValue(v);
    if(changeFunction!==undefined) {
        changeFunction(v);
    }
}

function getCopyProps<T>(values: ControlledProps<T>): any {
    return {
        id: values.id,
        name: values.name,
        style: values.style,
        required: values.required,
        disabled: values.disabled,
        onFocus: values.onFocus,
        onBlur: values.onBlur,
        maxLength: values.maxlength,
        pattern: values.pattern,
        autoComplete: values.autoComplete,
    };
}

function getClasses<T>(value: T, values: ControlledProps<T>) {
    return values.className + (values.checkValid!==undefined ? (values.checkValid(value)?" is-valid":" is-invalid") : "");
}

export default function InputControlled(props: InputControlledProps<string>): JSX.Element {
    const [value, setValue] = useState(props.value || "");

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);
    if(props.placeholder) copyProps['placeholder'] = props.placeholder;

    return <input value={value} className={classes} {...copyProps} type={props.type || "text"} onChange={(e) => onChange(e.target.value, setValue, props.handleChange)}/>;
}

export function InputNumberControlled(props: InputControlledProps<string> & {min?: number, max?: number, arrows?: boolean}): JSX.Element {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleValue = useCallback((val: string) => {
        //empty - ignore
        if(val==="" || val==="-") {
            onChange("" + val, setValue, props.handleChange);
            return;
        }

        //not empty - enforce boundaries
        let v : number = +val;
        if(props.min!==undefined && v < props.min) {
            v = props.min;
        }
        if(props.max!==undefined && v > props.max) {
            v = props.max;
        }
        onChange("" + v, setValue, props.handleChange);
    }, [props.handleChange, props.max, props.min]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        handleValue(e.target.value);
    }, [handleValue]);

    const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.value==="") {
            if(props.min) onChange("" + props.min, setValue, props.handleChange);
            else {
                const zero: string = "0";
                onChange(zero, setValue, props.handleChange);
            }
        }
    }, [props.handleChange, props.min]);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);
    if(props.placeholder) copyProps['placeholder'] = props.placeholder;

    return <div className="position-relative">
        <input value={value} className={classes} {...copyProps} type="text" inputMode="numeric" pattern="[0-9]*" onChange={handleChange} onBlur={handleBlur}/>
        {props.arrows && <div className="position-absolute d-flex flex-column h-100" style={{top: 0, right: 0}}>
            <button type="button" className="btn btn-secondary py-0 h-50 px-2" onClick={() => handleValue("" + (+value + 1))} style={{borderBottomRightRadius: 0, borderBottomLeftRadius: 0}}><ChevronUp style={{marginTop: "-8px"}}/></button>
            <button type="button" className="btn btn-secondary py-0 h-50 px-2" onClick={() => handleValue("" + (+value - 1))} style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}><ChevronDown style={{marginTop: "-8px"}} /></button>
        </div>}
    </div>;
}

export function TextareaControlled(props: ControlledProps<string>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <textarea value={value} className={classes} {...copyProps} onChange={(e) => onChange(e.target.value, setValue, props.handleChange)}/>;
}

export function SelectControlled(props: React.PropsWithChildren<ControlledProps<string>>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <select value={value} className={classes} {...copyProps} onChange={(e) => onChange(e.target.value, setValue, props.handleChange)}>
        {props.children}
    </select>;
}

export function SelectNumberControlled(props: React.PropsWithChildren<ControlledProps<number>>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <select value={value} className={classes} {...copyProps} onChange={(e) => onChange(+e.target.value, setValue, props.handleChange)}>
        {props.children}
    </select>;
}

export function CheckboxControlled(props: ControlledProps<boolean>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <input type="checkbox" checked={value} className={classes} {...copyProps} onChange={() => onChange(!value, setValue, props.handleChange)}/>;
}

interface SelectHtmlControlledOption<T> {
    id: any;
    value: T;
    html: JSX.Element;
}

interface SelectHtmlControlledProps<T> extends ControlledProps<T> {
    options: SelectHtmlControlledOption<T>[];
    selectedClass: "bg-primary" | string;
    optionClass: "rounded" | string;
}

export function SelectHtmlControlled(props: SelectHtmlControlledProps<string>): JSX.Element {
    const [value, setValue] = useState(props.value);

    let classes = getClasses(value, props);
    const copyProps = getCopyProps(props);

    return <div className={classes} {...copyProps}>
        {props.options.map((option: SelectHtmlControlledOption<string>) =>
            <div className={props.optionClass + (option.value===value ? " "+props.selectedClass : "")}
                 key={option.id}
                 onClick={() => onChange(option.value, setValue, props.handleChange)}>
                {option.html}
            </div>)}
    </div>;
}