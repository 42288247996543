export function indexGet<T>(list: T[], predicate: (item: T) => boolean): T | undefined {
    for(let i=list.length-1; i>=0; --i) {
        if(predicate(list[i])) return list[i];
    }
    return undefined;
}

export function dateStringToDate(dateString: string): Date {
    return new Date(Date.parse(dateString.replace(" ", "T")));
}

export function dateStringToString(dateString: string) {
    const d = dateStringToDate(dateString);
    return d.getDate() + "." + (d.getMonth()+1) + "." + d.getFullYear();
}

export function dateStringToTimeString(dateString: string) {
    const d = dateStringToDate(dateString);
    return dateStringToString(dateString) + " " + d.getHours() + ":" + d.getMinutes();
}

export function queryParamGet(queryString: string, queryParameter: string) {
    let index = queryString.indexOf(queryParameter + "=");
    if(index>=0) {
        let redirectQuery = queryString.substring(index+queryParameter.length + 1);

        index = redirectQuery.indexOf("&");
        if (index > 0) {
            return redirectQuery.substring(0, index);
        } else {
            return redirectQuery;
        }
    }
    return "";
}