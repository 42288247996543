import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import {gameProfileDto, gameUserInfoErrors, ProfileDto} from "./GameService";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";
import {ErrorObject, getError} from "../api/apiConfig";
import {AxiosError, AxiosResponse} from "axios";
import {MessageDisplay, MessageState} from "../components/Message";
import {Subscription} from "../payments/Subscription";
import {BooksBuyDisplay} from "../payments/BooksBuyDisplay";
import {authLoginTokenRequest, authTwitchConnect} from "../authentication/AuthenticationService";
import {ButtonFancy} from "../basic/ButtonFancy";
import InputControlled from "../components/InputControlled";
import LoginToken from "./LoginToken";

export const Profile = (): ReactElement => {
    // const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [dto, dtoSet] = useState<ProfileDto>();
    const navigate = useNavigate();

    const [submitting, submittingSet] = useState(false);

    useEffect(() => {
        // get user info request
        gameProfileDto().then((response: AxiosResponse<ProfileDto>) => {
            console.log(response.data);

            dtoSet(response.data);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, gameUserInfoErrors)});
        });
    }, [navigate])

    const twitchConnect = useCallback(() => {
        if(submitting) return;
        submittingSet(true);
        authTwitchConnect().then((response: AxiosResponse<string>) => {
            window.location.href = response.data;
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, gameUserInfoErrors)});
        });
    }, [submitting]);

    const subscriptionActive = dto!==undefined && new Date(dto.userInfo.subscriptionUntil)>new Date();

    return <div className="container d-flex align-items-center justify-content-center">
        <Card style={{maxWidth: "800px"}} className="w-100 bg-fancy text-white">
            <div className="bg-fancy-offset"/>
            <h2 className="text-center">Profile</h2>
            <MessageDisplay message={message}/>

            {dto!==undefined ? <div className="container">
                {/*name*/}
                <div className="text-center">
                    <div className="d-inline-block playerName">
                        {dto.userInfo.name}
                    </div>
                </div>

                {/*resources*/}
                <h4 className="mb-0">Resources</h4>
                <div className="row mb-2">
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/shield_18.png" width="60" style={{marginTop: '-3px'}} />
                            <div className="d-inline-block">Ranking Points: {dto.userInfo.mmr}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/MiningIcons_86_t.png" width="53" />
                            <div className="d-inline-block">Crystals: {dto.userInfo.crystals}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/Quest_35.png" width="68" style={{marginTop: '-6px'}} />
                            <div className="d-inline-block">Fairytales: {dto.userInfo.books}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/Icon_Crown.png" width="65" style={{marginTop: '-6px'}} />
                            <div className="d-inline-block">Crowns: {dto.userInfo.crowns}</div>
                        </div>
                    </div>
                </div>

                <h4>Ranking statistics</h4>
                <table className="table table-dark table-striped table-hover">
                    <thead>
                        <tr>
                            <td></td>
                            <td>Normal</td>
                            <td>Chaos</td>
                            <td>Async</td>
                            <td>Chaos Async</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ranking points</td>
                            <td>{dto.userInfo.mmr}</td>
                            <td>{dto.userInfo.mmrChaos}</td>
                            <td>{dto.userInfo.mmrAsync}</td>
                            <td>{dto.userInfo.mmrChaosAsync}</td>
                        </tr>
                        <tr>
                            <td>Peak (this season)</td>
                            <td>{dto.userInfo.mmrMax}</td>
                            <td>{dto.userInfo.mmrChaosMax}</td>
                            <td>{dto.userInfo.mmrAsyncMax}</td>
                            <td>{dto.userInfo.mmrChaosAsyncMax}</td>
                        </tr>
                        <tr>
                            <td>Lifetime games</td>
                            <td>{dto.userInfo.gameCountLife}</td>
                            <td>{dto.userInfo.gameCountLifeChaos}</td>
                            <td>{dto.userInfo.gameCountLifeAsync}</td>
                            <td>{dto.userInfo.gameCountLifeChaosAsync}</td>
                        </tr>
                        <tr>
                            <td>Lifetime average placement</td>
                            <td>{dto.userInfo.avgPlacementLife}</td>
                            <td>{dto.userInfo.avgPlacementLifeChaos}</td>
                            <td>{dto.userInfo.avgPlacementLifeAsync}</td>
                            <td>{dto.userInfo.avgPlacementLifeChaosAsync}</td>
                        </tr>
                    </tbody>
                </table>

                {/*Subscription*/}
                <div className="mb-3">
                    <h4 className="mb-0">Battle Pass</h4>
                    <div className="mb-2">
                        {subscriptionActive ? <div>
                            <div>Battle Pass <span className="text-success">active</span> until: {'' + dto.userInfo.subscriptionUntil}</div>
                            <div><Link to="https://billing.stripe.com/p/login/bIY6rC8rE3gz7kcdQQ" target="_blank">Manage your Battle Pass and view Payment History</Link></div>
                        </div> : <>
                            Battle Pass <span className="text-warning">not</span> active. Last Battle Pass was active until: {'' + dto.userInfo.subscriptionUntil}
                        </>}
                    </div>

                    <Subscription id="subscription" subscriptionActive={subscriptionActive} />
                </div>

                {/*Books*/}
                <div className="mb-3">
                    <h4>Buy Fairytales</h4>

                    <BooksBuyDisplay id="books-small" payment="books-small" dto={dto} />
                    <BooksBuyDisplay id="books-large" payment="books-large" dto={dto} />
                    <BooksBuyDisplay id="books-huge" payment="books-huge" dto={dto} />
                    <BooksBuyDisplay id="books-endless" payment="books-endless" dto={dto} />
                </div>

                {/*twitch connect*/}
                <div className="mb-3" id="twitch">
                    <h4>Twitch</h4>
                    <h6>Current connected twitch name: {dto.userInfo.twitchUsername ? dto.userInfo.twitchUsername : ''}</h6>
                    <ButtonFancy type="submit" disabled={submitting} onClick={twitchConnect}>
                        Connect with Twitch
                    </ButtonFancy>
                </div>
            </div> : <div>
                <AsyncLoadingAnimation height="400px" width="100%"/>
            </div>}
        </Card>
    </div>;
};
