import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../api/apiConfig";


export default function useLogout() {
    const navigate = useNavigate();

    const logout = useCallback(() => {
        removeToken();
        navigate("/app/login");
    }, [navigate])

    return logout
}