import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./styles.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// root.render(<React.StrictMode><RouterProvider router={appRouter} /></React.StrictMode>);
root.render(<App/>);

reportWebVitals();
