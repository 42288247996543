import {Button, Card, Form} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {AxiosError} from "axios";
import {ErrorObject, getError} from "../api/apiConfig";
import {MessageDisplay, MessageState} from "../components/Message";
import InputControlled from "../components/InputControlled";
import {authEmailVerify, authEmailVerifyErrors} from "./AuthenticationService";

interface ChangeData {
    code: string;
}

export const EmailVerifyPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);
    const [stage, stageSet] = useState(0);
    const [data] = useState<ChangeData>({code: ''});

    //load data: name, link
    const handleConfirm = useCallback(() => {
        if(submitting) return;
        const link = searchParams.get('link');
        if(!link) {
            // messageSet({type: "danger", message: "Entschuldigung, dieser Link scheint nicht zu funktionieren."})
            return;
        }

        submittingSet(true);
        authEmailVerify(link, data.code).then(() => {
            stageSet(1);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, authEmailVerifyErrors)});
        }).finally(() => submittingSet(false));
    }, [data.code, searchParams, submitting]);

    return <div className="container d-flex align-items-center justify-content-center" style={{height: "95vh"}}>
        <Card style={{maxWidth: "400px"}} className="w-100">
            {/*Input email*/ stage===0 && <>
                <Card.Body>
                    <Card.Title>Email Bestätigung</Card.Title>
                    <MessageDisplay message={message}/>

                    <Form.Group>
                        <Form.Label>Sicherheits Code von der Email</Form.Label>
                        <InputControlled value={data.code} className="form-control" handleChange={(v: string) => data.code = v}/>
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <div className='d-flex py-2 flex-column align-items-center'>
                        <Button variant="primary" onClick={handleConfirm} disabled={submitting || data.code.length!==4}>Email Bestätigen</Button>
                    </div>
                </Card.Footer>
            </>}
            {/*Open email message*/ stage===1 && <>
                <Card.Body>
                    <Card.Title>Email Bestätigung</Card.Title>
                    <MessageDisplay message={message}/>

                    <h4 className="text-success">Deine Email wurde bestätigt.</h4>
                </Card.Body>
            </>}
        </Card>
    </div>;
};