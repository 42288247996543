import React, {FormEvent, ReactElement, useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";
import {BookDict, BookInfo, BookInfoDto, paymentBook, PaymentBookTypes} from "./PaymentService";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorObject, getError} from "../api/apiConfig";
import {authLoginErrors} from "../authentication/AuthenticationService";
import {ButtonFancy} from "../basic/ButtonFancy";
import {ProfileDto} from "../game/GameService";
import {Check} from "react-bootstrap-icons";
import {ClimateBanner} from "../components/ClimateBanner";


export const BooksBuyDisplay = (props: {id?: string; payment: PaymentBookTypes, dto: ProfileDto}): ReactElement => {
    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);
    const [borderName, borderNameSet] = useState("");

    const bookInfo: BookInfo = BookDict[props.payment];
    const bookInfoDto: BookInfoDto = props.dto.books[props.payment];

    const borderChoose = useCallback((value: string): boolean => {
        if(props.dto.bundleBordersOwned.includes(value)) return false;
        borderNameSet(value);
        return true;
    }, [borderNameSet, props.dto]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        const paymentQuery = query.get("payment");
        if(paymentQuery && paymentQuery===props.payment) {
            if (query.get("success")) {
                messageSet({type: 'success', message: "Payment confirmed! You will shortly receive an email confirmation."});
            }

            if (query.get("canceled")) {
                messageSet({type: 'warning', message: "Order cancelled."});
            }

            if(props.id) {
                const scrollTarget = document.getElementById(props.id);
                if(scrollTarget) scrollTarget.scrollIntoView();
            }
        }

        // init cosmetic: not yet owned
        const list = props.dto.books[props.payment].borders;
        if(list.length>0) {
            for(let i=0; i<list.length; ++i) {
                const set: boolean = borderChoose(list[i]);
                if(set) break;
            }
        }
    }, [props.payment, borderNameSet, borderChoose, props.dto, props.id]);

    const handleCheckout = useCallback((e: FormEvent): void => {
        e.preventDefault();
        if(submitting) return;
        submittingSet(true);
        paymentBook(props.payment, borderName).then((response: AxiosResponse<string>) => {
            // go to URL
            window.location.href = response.data;
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, authLoginErrors)});
            submittingSet(false);
        }); // sic!: NO submittingSet(false) - go to different href
    }, [submitting, props.payment, borderName]);

    return <div className="position-relative">
        <div id={props.id} className="scroll-target" />
        <form onSubmit={handleCheckout}>
            <div className="border rounded-2 border-2 border-dark p-1">
                <MessageDisplay message={message} />

                <div className="d-flex flex-column flex-md-row justify-content-center">
                    <div className="text-center px-3 d-flex flex-column justify-content-center">
                        <img width="180" className="d-inline-block filter-saturate"
                             src={"/assets/img/" + props.payment + ".png"}
                             alt=""
                        />
                    </div>
                    <div className="description p-3 d-flex flex-column justify-content-center">
                        <h3 className="mb-0">{bookInfo.name}</h3>
                        <h5 className="text-center">${bookInfo.price}</h5>
                        <h5 className="mb-1">
                            <ul>
                                <li><img className="resource-icon d-inline" alt="" src="/assets/img/Quest_35.png" width="38" />
                                    {bookInfoDto.amount} Fairytales</li>
                                {bookInfoDto.borders.length>0 && <li>Unlock a Border of your choice exclusive to this bundle. View the Animation in-game.</li>}
                            </ul>
                        </h5>

                        {bookInfoDto.borders.length>0 && <div className="mb-3 row">
                            {bookInfoDto.borders.map((value: string) =>
                                <button key={value} onClick={() => borderChoose(value)} type="button" disabled={props.dto.bundleBordersOwned.includes(value)}
                                     className={"border position-relative border-dark p-1 col-12 col-md-6 " + (borderName===value ? "bg-purple":"bg-dark")}>
                                    <img className={" " + ((!props.dto.bundleBordersOwned.includes(value) && borderName!==value) ? "filter-gray":"resource-icon")} alt="" src={"/assets/img/" + value + ".png"} width="120" />

                                    {props.dto.bundleBordersOwned.includes(value) ? <div className="rounded-2 d-inline-block text-white">
                                        <Check className="text-success" style={{marginTop: "-6px", marginBottom: "-4px"}} width={40} height={40}/>
                                        <h6 className="d-inline-block mb-0 fw-bold" style={{marginLeft: "-2px", marginRight: '12px'}}>{props.dto.bundleBordersNamesDict[value]}</h6>
                                    </div> : <div>
                                        <h6 className={"mt-2 text-center text-white " + (borderName===value ? "fw-bold":"")}>{props.dto.bundleBordersNamesDict[value]}</h6>
                                    </div>}
                            </button>)}
                        </div>}

                        <h6 className="mb-3">{bookInfo.description}</h6>
                        <ButtonFancy className="w-100 mb-2" bg="purple" type="submit" disabled={submitting}>
                            Checkout
                        </ButtonFancy>
                        <ClimateBanner />
                    </div>
                </div>
                <div className="text-center">
                </div>
            </div>
        </form>
    </div>;
};