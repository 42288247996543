import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import {Footer} from "../basic/Footer";
import {useEffect} from "react";
import {analyticsPageViewCurrent} from "../analytics/AnalyticsService";

export default function WebpageOutlet(props: {}) {
    useEffect(() => {
        analyticsPageViewCurrent();
    }, [props]);

    const navLinks = [
        {to: "/", title: "Home", end: true},
        {to: "/articles", title: "Articles", end: true},
        {to: "/twitch", title: "Twitch", end: true},
        {to: "/tournaments", title: "Tournaments", end: true},
        // {to: "/app/friends", title: "Freunde"},
        // {to: "/app/list", title: "Geschenke"},
        // {to: "/app/profile", title: "Profile"}
    ]

    return <>
        <Header links={navLinks} webpage/>
        <div className='d-md-none header-space'/>
        <Outlet/>
        {/*<iframe src={"https://twitch.tv/mattoblivium"} width={1024} height={576}></iframe>*/}
        <Footer/>
    </>
}